import React, {useContext, useEffect, useRef, useState } from "react";
import Header from "../Components/Appbar";
import Appfooter from "../Components/Appfooter";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import "../index.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import displayPics from "../Assets/Images/MentorProfilePics.jpg";
import aiDisplayPics from "../Assets/Images/ChatBotImage.jpg";
import { Link, useNavigate } from "react-router-dom";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import ButtonIcon from "../Components/Buttonicon";
import MarkdownRenderer from "../Components/MarkdownRenderer";
import { v4 as uuidv4 } from "uuid";
import UserContext from "../Components/Controller";
import { Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import CustomHook from "../CustomHook";
import InAppLoader from "./InAppLoader";

const Chat = () => {
  // State declarations
  let [currentPlan, setCurrentPlan] = useState({});
  let [chatPageVisibility, setChatPageVisibility] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // State for managing response message
  const [message, setMessage] = useState(null);
  // State for prompt input value
  const [inputValue, setInputValue] = useState(null);
  // State for storing previous chats
  const [previousChats, setPreviousChats] = useState([]);
  // State for current conversation title
  const [currentTitle, setCurrentTitle] = useState(null);
  // State for handling conversation history
  const [conversationHistory, setConversationHistory] = useState([]);
  // State for assigning unique conversation IDs
  const [conversationId, setConversationId] = useState("");
  //STATE FOR LOADING EFFECT ON BUTTONS
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate(); // Initialize navigate function for navigation

  const chatContainerRef = useRef(null) //Useref for chat container scroll on message update.

  const { fetchUserData } = CustomHook(); // Fetch the fetchUserData function from your custom hook

  const { currentUserDetails, smallLoading, setSmallLoading, getSingleChat, } = useContext(UserContext); // Get currentUserDetails from UserContext

  const callFetchUserData = async () => {
    const userDataResponse = await fetchUserData();
    if (userDataResponse.status === 401) {
      navigate('/userAuth/login');
    }
  };

  useEffect(() => {
    callFetchUserData();
  }, [message]);

  // Ensure currentUserDetails is not null or undefined before destructuring
  const { userId, name, plan, conversations, isAccountActive } = currentUserDetails || {};

  console.log(isAccountActive);

  // Update previous chats state when userCredentials is here
  const updateCurrentUsersConversationHistory = () => {
    if (!conversations) return;

    const newChats = [];

    for (const [id, messages] of Object.entries(conversations)) {
      if (Array.isArray(messages.history)) { // Check if 'history' is an array
        messages.history.forEach((message) => {
          newChats.push({
            conversationId: id,
            title: message.title,
            role: message.role,
            content: message.content,
          });
        });
      } else {
        console.error(`Expected an array for messages.history, but got:`, messages.history);
      }
    }

    setPreviousChats((prevChats) => [...prevChats, ...newChats]);
  };



  // Check if conversations is an array whenever currentUserDetails changes
  useEffect(() => {
    updateCurrentUsersConversationHistory();
  }, [currentUserDetails]);


  const loginStatus = localStorage.getItem("loginStatus"); // Get login status from localStorage

  // Redirect to login page if user is not logged in
  useEffect(() => {
    if (loginStatus === "false" || loginStatus === null) {
      navigate("/userAuth/login");
    }
  }, [loginStatus, navigate]);




  // Predefined prompt options
  const alreadyMadePrompt = [
    {
      id: 1,
      title: "Get Goal",
      description:
        "Get my saved goal me",
    },
    {
      id: 2,
      title: "Fetch my progres",
      description: "Can you write out my progress report",
    },
    {
      id: 3,
      title: "Resume Progress",
      description: "I need you to resume my progress for me",
    },
  ];

  // Function to start a new conversation
  const newConversation = () => {
    setMessage(null);
    setInputValue(null);
    setCurrentTitle(null);
    setChatPageVisibility(false);
    setConversationId(uuidv4()); // Generate a new unique ID
    setConversationHistory([]);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Set input value based on selected prompt
  const addAlreadyMadeInputValue = (selectedItemId) => {
    let findIndex = alreadyMadePrompt.find(
      (item) => item.id === selectedItemId
    );
    let { title, description } = findIndex;
    setInputValue(`${title}. ${description}`);
  };

  // Handle input change in the prompt field
  const handlePromptInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle prompt submission
  const handlePromptSubmit = async (e) => {
    e.preventDefault();

    if (inputValue === '') {
      return null
    } else {
      setLoading((prevValue) => {
        return !prevValue
      })

      let currentConversationId = conversationId; // Use current conversation ID

      // Generate a new conversation ID if not present
      if (!currentConversationId) {
        currentConversationId = `${userId}-${Date.now()}`;
        setConversationId(currentConversationId); // Update state with new conversation ID
      }

      const options = {
        method: "POST",
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({
          message: inputValue,
          conversationId: currentConversationId,
          conversationHistory,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch("https://api.thementornextdoor.com/prompt/", options); // Make API request
        const data = await response.json();

        // Check if data and data.message are defined
        if (data && data.message) {
          setMessage(data.message);
          setConversationHistory(data.conversationHistory || []);
          setChatPageVisibility(true);

          //set Loading to false
          setLoading((prevValue) => {
            return !prevValue
          })

          if (!currentTitle && inputValue) {
            setCurrentTitle(inputValue); // Set current title if not already set
          }

          // Update previous chats state
          setPreviousChats((prevChats) => [
            ...prevChats,
            {
              conversationId: data.conversationId,
              title: currentTitle || inputValue,
              role: "user",
              content: inputValue,
            },
            {
              conversationId: data.conversationId,
              title: currentTitle || inputValue,
              role: "assistant",
              content: data.message.content, // Only set if content exists
            },
          ]);

          //cleaning input field 
          setInputValue('')

        } else {
          toast.error("Failed to get a valid response from the server.");
          //set Loading to false
          setLoading((prevValue) => {
            return !prevValue
          })
        }
      } catch (error) {
        //set Loading to false
        setLoading((prevValue) => {
          return !prevValue
        })
        toast.error("Failed to communicate with the server."); // Show error toast
      }
    }


  };


  // Update title and previous chats when message changes
  useEffect(() => {
    if (inputValue && message) {
      const newChats = [
        {
          conversationId: conversationId,
          title: currentTitle || inputValue,
          role: "user",
          content: inputValue,
        },
        {
          conversationId: conversationId,
          title: currentTitle || inputValue,
          role: "assistant",
          content: message.content,
        },
      ];
      setPreviousChats((prevChats) => [...prevChats, ...newChats]);
    }
  }, [message, currentTitle, conversationId, inputValue]);


  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [message]);

  // Handle chat item click
  const handleChatClick = async (uniqueTitle) => {
    console.log(uniqueTitle);

    if (uniqueTitle === currentTitle) {
      return null;
    } else {
      try {
        setSmallLoading(true);

        // Fetch the chat data
        const fetchedChat = await getSingleChat(uniqueTitle);

        if (fetchedChat && fetchedChat.length > 0) {
          // Setting conversation history with fetched data
          setConversationHistory(
            fetchedChat.map((chat) => ({
              role: chat.role,
              content: chat.content,
            }))
          );

          console.log('Here is the selected chat history:', fetchedChat);

          setCurrentTitle(uniqueTitle);
          setMessage(null);
          setInputValue(null);
          setChatPageVisibility(true);
        } else {
          throw new Error("No conversation found");
        }

      } catch (error) {
        console.error("An error occurred:", error);
        toast.error("An error occurred while fetching the conversation. Please try again.");
      } finally {
        setSmallLoading(false);
      }
    }
  };


  // Update current plan based on user plan from localStorage
  useEffect(() => {
    let planObject = [
      {
        planName: "The Mentor Next Door - Monthly Plan",
        planPrice: "£19.00",
      },
      {
        planName: "The Mentor Next Door - Annual Plan",
        planPrice: "£190.00",
      },
    ];

    const addCurrentPlan = () => {

      let myPlan = planObject.find((thisUserplan) => thisUserplan.planName === plan);

      if (myPlan) {
        setCurrentPlan(myPlan);
      } else {
        setCurrentPlan({ planName: "Default Plan", planPrice: "$0.00" }); // Default plan if userPlan not found
      }
    };

    addCurrentPlan();

  }, [plan]);

  const titlesAndIds = conversations?.map(conversation => {
    return {
      id: conversation.id,
      titles: conversation.history.map(item => item.title) // Extract titles from history
    };
  });

  //Handling message submit to guide againt unsubscribed users
  const handleMessageSendingSubmit = (e) => {
    e.preventDefault()
    if (isAccountActive) {
      handlePromptSubmit(e);
    } else {
      toast.error('Your account is currently inactive')
    }
  };

  return (
    <>
      {currentUserDetails && Object.keys(currentUserDetails).length === 0 ? (
        <div className="w-full h-lvh flex bg-[#F1F5F9] items-center justify-center">
          <Spinner size="xl" color="info" aria-label="Default status example" />
        </div>
      ) : (
        <>
        {
          !isAccountActive && <InAppLoader />
        }
        <div className="w-full flex flex-col items-center justify-center">
          <Header headerNavType="profile" />
          <section className="w-full relative h-[90vh] md:h-[87vh] bg-[#F1F5F9] gap-3 flex items-center justify-center px-2 py-4 md:px-[3vw]">
            <section
              onClick={toggleSidebar}
              className="fixed z-40 top-24 right-6 bg-[#E2E8F0] sm:flex p-2 shadow-sm md:hidden rounded-md cursor-pointer items-center justify-end"
            >
              <MenuOutlinedIcon
                style={{ color: "#8390A3", fontSize: "25px" }}
              />
            </section>
            <aside
              className={`fixed h-full inset-y-0 left-0  w-[350px] bg-white shadow-lg transition-transform px-2 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                } md:relative md:translate-x-0 md:flex md:flex-col md:items-center md:gap-2 md:px-2 md:py-2 md:justify-between rounded-md`}
            >
              <div className="w-full items-center py-3 justify-center">
                <p
                  onClick={newConversation}
                  className="capitalize gap-2 text-sm text-[#2563EB] cursor-pointer flex items-center w-full justify-start"
                >
                  <AddCircleIcon
                    style={{ fontSize: "16px", color: "#2563EB" }}
                  />
                  new conversation
                </p>
              </div>
              <ul className="list-none scrollbar-hide group-hover:scrollbar-show overflow-y-auto flex gap-2 flex-col items-center w-full justify-start h-full">
                {/** SIDE BAR */}
                {titlesAndIds?.map((uniqueTitle, index) => (
                  <li
                    key={index}
                    onClick={() => handleChatClick(uniqueTitle.id)}
                    className="flex cursor-pointer flex-shrink-0 w-full gap-2 rounded-md py-4 px-5 overflow-hidden items-center text-[#475569] justify-center bg-[#DBEAFE]"
                  >
                    <QuestionAnswerIcon
                      style={{ color: "#475569", fontSize: "18px" }}
                    />
                    <p
                      id={index}
                      className="text-[#475569] text-left text-[13px] capitalize whitespace-nowrap text-ellipsis w-full overflow-x-clip"
                    >
                      {uniqueTitle.titles[0]}
                    </p>
                  </li>
                ))}
              </ul>
              <div className="py-2 sm:px-2 sm:py-2 w-full">
                <Link
                  to="/packages"
                  className="flex justify-between p-4 rounded-lg border border-slate-200 dark:border-slate-800 hover:border-blue-200 hover:dark:border-blue-950 transition-all"
                >
                  <div>
                    <span className="text-[11px] font-medium capitalize text-blue-500 bg-gradient-to-r from-blue-100 dark:from-blue-950 to-pink-100 dark:to-pink-950 px-2 py-1 rounded">
                      current plan
                    </span>
                    <h3 className="mt-2 font-bold text-sm text-slate-600 dark:text-slate-100">
                      {currentPlan.planName}
                    </h3>
                  </div>
                  <div className="text-end">
                    <div className="font-bold text-lg bg-gradient-to-r from-blue-600 to-pink-500 text-transparent bg-clip-text">
                      {currentPlan.planPrice}
                    </div>
                  </div>
                </Link>
              </div>
            </aside>
            <div className="flex-grow min-w-0 h-full flex flex-col items-center justify-center">
              {!chatPageVisibility ? (
                <section className="w-full flex h-full flex-col items-center justify-between p-3">
                  <div className="md:pt-4 pt-2 pb-7 flex flex-col w-full items-center justify-start">
                    <h2 className="font-bold text-left w-full text-3xl pb-2 bg-gradient-to-r from-blue-600 to-pink-500 text-transparent bg-clip-text">
                      Hi, {name}
                    </h2>
                    <h4 className="font-bold text-2xl w-full text-left text-slate-600 dark:text-slate-100">
                      What are we achieving today?
                    </h4>
                  </div>
                  <div className="flex flex-col gap-6">
                    <div className="h-full flex items-start justify-center flex-col">
                      <h5 className="font-bold text-sm mb-3 text-slate-700 dark:text-slate-100">
                        Let's try
                      </h5>
                      <div className="grid grid-flow-dense sm:grid-cols-2 md:grid-cols-3 gap-5">
                        {alreadyMadePrompt.map((item, index) => {
                          let { title, id, description } = item;
                          return (
                            <div
                              key={index}
                              id={id}
                              onClick={() => addAlreadyMadeInputValue(id)}
                              className="px-4 py-3 cursor-pointer rounded-md bg-white dark:bg-slate-950 border border-slate-200 dark:border-slate-800"
                            >
                              <h6 className="font-bold text-base mb-1 text-slate-600 dark:text-slate-100">
                                {title}
                              </h6>
                              <p className="text-sm line-clamp-1 text-slate-500 dark:text-slate-300">
                                {description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <ul ref={chatContainerRef} className="w-full chatUl h-full md:h-full bg-white flex flex-col overflow-auto rounded-md gap-2 items-start justify-start p-3">
                  {
                    smallLoading ? (<div className="w-full h-lvh flex bg-[#F1F5F9] items-center justify-center">
                      <Spinner size="xl" color="info" aria-label="Default status example" />
                    </div>) :
                      (
                        conversationHistory.map((chatItems, index) => (
                          <li
                            key={index}
                            className="w-full chatUl flex flex-col justify-start items-center p-3"
                          >
                            <div className="w-full flex gap-4 justify-start items-center flex-shrink-0">
                              {chatItems.role === "assistant" ? (
                                <img
                                  src={aiDisplayPics}
                                  alt="ai img"
                                  className="w-[40px] h-[40px] rounded-full mb-2"
                                />
                              ) : (
                                <img
                                  src={displayPics}
                                  alt="ai img"
                                  className="w-[40px] h-[40px] rounded-full mb-2"
                                />
                              )}
                              <h1 className="font-bold text-md capitalize text-[#475569]">
                                {chatItems.role === "assistant"
                                  ? "Misha"
                                  : "You"}
                              </h1>
                            </div>
                            <div className="w-full items-start ml-3 mt-2 justify-center gap-1 flex flex-col">
                              <div className="text-[#475569] w-full flex flex-col items-start justify-center font-normal text-lg leading-relaxed">
                                <MarkdownRenderer
                                  content={chatItems.content}
                                  className="chatUl"
                                />
                              </div>
                            </div>
                          </li>
                        ))
                      )
                  }
                </ul>
              )}
              <div className="pt-3 w-full">
                <form className="flex items-start w-full gap-4 mt-6 md:mt-3">
                  <textarea
                    title="prompt"
                    name="promptInput"
                    value={inputValue}
                    onChange={handlePromptInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        handleMessageSendingSubmit(e); // Trigger message submit
                      }
                    }}
                    className="py-2.5 px-4 z-10 w-full max-w-full max-h-[80px] h-[45px] rounded-md text-sm/[1.125rem] bg-white dark:bg-slate-950 text-slate-600 dark:text-slate-200 placeholder:text-slate-400 placeholder:dark:text-slate-500 border border-slate-200 dark:border-slate-800 disabled:bg-slate-100 disabled:text-slate-400 focus:border-blue-200 focus:shadow-none focus:outline-none"
                  />
                  <ButtonIcon
                    className="bg-blue-600 text-white hover:bg-blue-800"
                    onClick={(e) => handleMessageSendingSubmit(e)}
                  >
                    {
                      loading ?
                        <div role="status">
                          <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                          <span class="sr-only">Loading...</span>
                        </div> :
                        <PaperAirplaneIcon className="h-4" />
                    }
                  </ButtonIcon>
                </form>
              </div>
            </div>
          </section>
          <Appfooter />
        </div>
        </>
      )}
    </>
  );
};

export default Chat;
